import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import toastr from "toastr";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/toastr.min.css";

import { IRootState } from "./redux/rootReducer";
import { IUserState } from "./redux/user/userReducer";
import { EPageLinks } from "./configs/types";
import { userActionSignOut } from "./redux/user/userActions";

import NotFound from "./pages/404";

import { refreshToken } from "./services/users/userServices";
import { getFormats, getTags } from "./services/tags/tagServices";
import { dataActionGetFormats, dataActionGetTags } from "./redux/data/dataActions";
import ErrorBoundary from "./components/ErrorBoundary";
import ScrollButton from "./components/ScrollButton";
import Chatbot from "./components/Chatbot";

const SignIn = React.lazy(() => import("./pages/sign-in"));

const BackendLayout = React.lazy(() => import("./layout/backend-layout/index"));

toastr.options.closeButton = true;

function App() {
  const { user } = useSelector<IRootState, { user: IUserState }>((state) => ({ user: state.user }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.id) return;

    const tokenIssuedAt = localStorage.getItem("tokenIssuedAt");
    const tokenExpiresIn = localStorage.getItem("tokenExpiresIn");

    getTags().then((tags) => dispatch(dataActionGetTags(tags)));
    getFormats().then((formats) => dispatch(dataActionGetFormats(formats)));

    if (!tokenIssuedAt || !tokenExpiresIn || +tokenIssuedAt + +tokenExpiresIn - Date.now() < 0) {
      dispatch(userActionSignOut());
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    const listener = async () => {
      if (!localStorage.getItem("token")) return;

      const tokenIssuedAt = localStorage.getItem("tokenIssuedAt") || "";
      const tokenExpiresIn = localStorage.getItem("tokenExpiresIn") || "";

      if (+tokenIssuedAt + +tokenExpiresIn - Date.now() >= 120000) return;

      const response = await refreshToken();

      if (!response.access_token) return;
      localStorage.setItem("token", response.access_token);
      localStorage.setItem("tokenExpiresIn", response.expires_in);
      localStorage.setItem("tokenIssuedAt", Date.now().toString());
    };

    const timer = setInterval(listener, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Suspense fallback={<></>}>
       {process.env.NODE_ENV === "development" ? null : <Chatbot /> }
      <ScrollButton />
      <ErrorBoundary>
        <Routes>
          {/* check login */}
          <Route path={EPageLinks.SignIn} element={user.id ? <Navigate to={user.roles?.find((role) => role.name === "User") ? "/" : EPageLinks.Dashboard} /> : <SignIn />} />

          {/* Backend Site Layout */}
          <Route path="/*" element={!user.id ? <Navigate to={EPageLinks.SignIn} /> : user.roles?.find((role) => role.name === "User") ? <Navigate to={"/"} /> : <BackendLayout />} />

          {/* 404 */}
          <Route path={EPageLinks.Exception} element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
