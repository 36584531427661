import { createSelector } from "reselect";

import { IRootState } from "../rootReducer";

const userSelector = (state: IRootState) => state.user;

export const selectUserId = createSelector([userSelector], (user) => user.id);

export const selectUserEmail = createSelector([userSelector], (user) => user.email);

export const selectUserRoles = createSelector([userSelector], (user) => user.roles);

export const selectVendorId = createSelector([userSelector], (user) => user.vendor_id);

export const selectTheme = createSelector([userSelector], (user) => user.theme);

export const selectPPT = createSelector([userSelector], (user) => user.ppt);

export const selectUserIsAdmin = createSelector([selectUserRoles], (roles) => !!roles.find((role) => role.name === "Administrator"));

export const selectUserIsSuperAdmin = createSelector([selectUserRoles], (roles) => !!roles.find((role) => role.name === "Superadmin"));

export const selectUserIsSOM = createSelector([selectUserRoles], (roles) => !!roles.find((role) => role.name === "Sales Order Management"));

export const selectUserEmploymentCode = createSelector([userSelector], (user) => user.employee_code);
