import { IAction } from "../../configs/types";
import { IFormat, ITag } from "../../services/tags/tagTypes";

export enum EDataActionTypes {
  "GetTags" = "GET_TAGS",
  "CreateTag" = "CREATE_TAG",
  "UpdateTag" = "UPDATE_TAG",
  "DeleteTag" = "DELETE_TAG",
  "GetFormats" = "GET_FORMATS",
  "GetCompareFormats" = "GET_COMPARE_FORMATS",
  "RemoveCompareFormats" = "REMOVE_COMPARE_FORMATS",
  "CreateFormat" = "CREATE_FORMAT",
  "UpdateFormat" = "UPDATE_FORMAT",
  "DeleteFormat" = "DELETE_FORMAT",
}

export const dataActionGetTags = (tags: ITag[]): IAction<EDataActionTypes, ITag[]> => ({
  type: EDataActionTypes.GetTags,
  payload: tags,
});


export const dataActionCreateTag = (tag: ITag): IAction<EDataActionTypes, ITag> => ({
  type: EDataActionTypes.CreateTag,
  payload: tag,
});

export const dataActionUpdateTag = (tag: ITag): IAction<EDataActionTypes, ITag> => ({
  type: EDataActionTypes.UpdateTag,
  payload: tag,
});

export const dataActionDeleteTag = (id: number): IAction<EDataActionTypes, number> => ({
  type: EDataActionTypes.DeleteTag,
  payload: id,
});

export const dataActionGetFormats = (formats: IFormat[]): IAction<EDataActionTypes, IFormat[]> => ({
  type: EDataActionTypes.GetFormats,
  payload: formats,
});

export const dataActionGetCompareFormats = (formats: IFormat[]): IAction<EDataActionTypes, IFormat[]> => ({
  type: EDataActionTypes.GetCompareFormats,
  payload: formats,
});

export const dataActionRemoveCompareFormats = (): IAction<EDataActionTypes> => ({
  type: EDataActionTypes.RemoveCompareFormats,
});

export const dataActionCreateFormat = (format: IFormat): IAction<EDataActionTypes, IFormat> => ({
  type: EDataActionTypes.CreateFormat,
  payload: format,
});

export const dataActionUpdateFormat = (format: IFormat): IAction<EDataActionTypes, IFormat> => ({
  type: EDataActionTypes.UpdateFormat,
  payload: format,
});

export const dataActionDeleteFormat = (id: number): IAction<EDataActionTypes, number> => ({
  type: EDataActionTypes.DeleteFormat,
  payload: id,
});
