import React, { useEffect } from "react";
import { selectUserId } from "../../redux/user/userSelectors";
import { useSelector } from "react-redux";

const Chatbot: React.FC = () => {
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (!userId) {
      // If the user is signed out, remove the chatbot script if it exists
      const script = document.querySelector("#chatbot-script");
      if (script) {
        document.body.removeChild(script);
      }
      return;
    }

    // Add the chatbot configuration to the window object
    window.embeddedChatbotConfig = {
      chatbotId: "RcX_QpVSiUFezmv_c0TcX",
      domain: "www.chatbase.co",
    };

    // Check if the script already exists to avoid loading it multiple times
    const existingScript = document.querySelector("#chatbot-script");
    if (!existingScript) {
      // Create and add the chatbot script
      const script = document.createElement("script");
      script.id = "chatbot-script";
      script.src = "https://www.chatbase.co/embed.min.js";
      script.setAttribute("chatbotId", "RcX_QpVSiUFezmv_c0TcX");
      script.setAttribute("domain", "www.chatbase.co");
      script.defer = true;
      document.body.appendChild(script);
    }

    // Cleanup function to remove the script when component unmounts or user logs out
    return () => {
      const script = document.querySelector("#chatbot-script");
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [userId]);

 

  return (
    <div id="chatbot-container"></div>
  );
};

export default Chatbot;
