import { Link } from "react-router-dom";
import { EPageLinks } from "../../configs/types";

export default function NotFound() {
  return (
    <div className="p-5 text-center">
      <h1 className="mt-5">404 | NOT FOUND</h1>
      <h3>
        It seems you are lost. Please <Link to={EPageLinks.Home}>Click Here</Link> to go to Home Page.
      </h3>
    </div>
  );
}
