import { callApi } from "..";
import { EFormatFieldNames, ETagFieldNames, IFormat, ITag } from "./tagTypes";
import { IFormData } from "../../configs/types";

export const createTag = async (formData: ITag) => {
  const response = await callApi.post<{ id: number }>("/highlight/store", {
    highlight_category_id: formData[ETagFieldNames.HighlightCategory],
    title: formData[ETagFieldNames.Title],
    text_color: formData[ETagFieldNames.Color],
    bg_color: formData[ETagFieldNames.BackgroundColor],
    field_name: formData[ETagFieldNames.ColumnName],
  } as unknown as IFormData);

  return response.responseData?.id ? { message: "Success.", data: { id: response.responseData.id } } : { error: "Something went wrong." };
};
export const updateTag = async (formData: ITag) => {
  const response = await callApi.patch<undefined>(`/highlight/update/${formData.id}`, {
    id: formData[ETagFieldNames.ID],
    highlight_category_id: formData[ETagFieldNames.HighlightCategory],
    title: formData[ETagFieldNames.Title],
    text_color: formData[ETagFieldNames.Color],
    bg_color: formData[ETagFieldNames.BackgroundColor],
    field_name: formData[ETagFieldNames.ColumnName],
  } as unknown as IFormData);

  return response.responseData?.id ? { message: "Success.", data: { id: response.responseData.id } } : { error: "Something went wrong." };
};
export const deleteTag = async (id: number) => {
  const response = await callApi.delete<undefined>(`/highlight/remove/${id}`);
  return !((response.statusCode || 0) > 400) ? { message: "Success." } : response;
};

export const getTagsCss = async () => {
  const response = await callApi.get(`/highlight/tags-css`);
  return response;
};





export const getTags = async (): Promise<ITag[]> => {
  const response = await callApi.get(`/highlight`);

  return !Array.isArray(response.responseData)
    ? []
    : response.responseData.map((tag) => ({
        id: tag.id,
        title: tag.title,
        color: tag.text_color,
        backgroundColor: tag.bg_color,
        columnName: tag.field_name,
        highlightCategoryId: tag.highlight_category_id,
        highlightCategoryName: tag.highlight_category_name,
      }));
};
export const createFormat = async (formData: IFormat) => {
  const response = await callApi.post<{ id: number }>("/highlight_format/store", {
    highlight_id: formData[EFormatFieldNames.TagID],
    format: formData[EFormatFieldNames.Text],
  } as unknown as IFormData);

  return response.responseData?.id || typeof response.responseData === "number" ? { message: "Success.", data: { id: response.responseData?.id || Math.random(), alreadyTagId: response.responseData } } : { error: "Something went wrong." };
};
export const updateFormat = async (formData: IFormat) => {
  const response = await callApi.patch<undefined>(`/highlight_format/update/${formData.id}`, {
    highlight_category_id: formData[EFormatFieldNames.HighlightCategory],
    highlight_id: formData[EFormatFieldNames.TagID],
    format: formData[EFormatFieldNames.Text],
  } as unknown as IFormData);

  return response.responseData?.id ? { message: "Success.", data: { id: response.responseData.id } } : { error: "Something went wrong." };
};
export const deleteFormat = async (id: number) => {
  const response = await callApi.delete<undefined>(`/highlight_format/remove/${id}`);
  return !((response.statusCode || 0) > 400) ? { message: "Success." } : response;
};

export const getFormats = async (): Promise<IFormat[]> => {
  const response = await callApi.get(`/highlight_format`);

  return !Array.isArray(response.responseData) ? [] : response.responseData.map(({ id, highlight_id: tagId, format: text }) => ({ id, tagId, text }));
};
