import toastr from "toastr";

import { IAction } from "../../configs/types";
import { IFormat, ITag } from "../../services/tags/tagTypes";
import { EDataActionTypes } from "./dataActions";

export interface IDataState {
  tags: ITag[];
  formats: IFormat[];
}

const initialState = Object.freeze<IDataState>({
  tags: [],
  formats: [],
});

export default function dataReducer(state = initialState, action: IAction<EDataActionTypes, ITag | IFormat | ITag[] | IFormat[] | number>): IDataState {
  switch (action.type) {
    case EDataActionTypes.GetTags:
      return {
        ...state,
        tags: action.payload as ITag[],
      };
    case EDataActionTypes.CreateTag:
      return {
        ...state,
        tags: [...state.tags, { ...(action.payload as ITag) }],
      };
    case EDataActionTypes.UpdateTag:
      return {
        ...state,
        tags: state.tags.map((tag) => (tag.id !== (action.payload as ITag).id ? tag : { ...tag, ...(action.payload as ITag) })),
      };
    case EDataActionTypes.DeleteTag:
      return {
        ...state,
        tags: state.tags.filter((tag) => tag.id !== action.payload),
      };
    case EDataActionTypes.GetFormats:
      return {
        ...state,
        formats: action.payload as IFormat[],
      };
    case EDataActionTypes.GetCompareFormats:
      const tags = (action.payload as IFormat[]).filter((f) => !state.formats.find((ft) => ft.id === f.id));
      return {
        ...state,
        formats: [...state.formats, ...tags.map((f) => ({ ...f, forCompare: true }))],
      };
    case EDataActionTypes.RemoveCompareFormats:
      return {
        ...state,
        formats: state.formats.filter((f) => !f.forCompare),
      };
    case EDataActionTypes.CreateFormat:
      if (state.formats.find((f) => f.text === (action.payload as IFormat).text)) {
        toastr.warning(`This format already is in use in '${state.tags.find((t) => t.id === (action.payload as IFormat).tagId)?.title}' Tag!`);
        return state;
      }
      return {
        ...state,
        formats: [...state.formats, { ...(action.payload as IFormat) }],
      };
    case EDataActionTypes.UpdateFormat:
      return {
        ...state,
        formats: state.formats.map((format) => (format.id !== (action.payload as IFormat).id ? format : { ...format, ...(action.payload as IFormat) })),
      };
    case EDataActionTypes.DeleteFormat:
      return {
        ...state,
        formats: state.formats.filter((format) => format.id !== action.payload),
      };
    default:
      return state;
  }
}
