import { callApi } from "..";
import { EUserFieldNames, IUser } from "./userTypes";
import { objToQueryString } from "../../configs/helpers";
import { EApiNames, IApis, IFormData, IPage } from "../../configs/types";

export const userInitialForm: IUser = Object.freeze({
  [EUserFieldNames.ID]: null,
  [EUserFieldNames.FirstName]: "",
  [EUserFieldNames.LastName]: "",
  [EUserFieldNames.Email]: "",
  [EUserFieldNames.Password]: "",
  [EUserFieldNames.Username]: "",
  [EUserFieldNames.Roles]: [],
  [EUserFieldNames.Phone]: null,
  [EUserFieldNames.EmployeeCode]: "",
  [EUserFieldNames.VendorID]: "",
  [EUserFieldNames.PPT]: 0,
  [EUserFieldNames.Theme]: 0,
});

export const userInitialSearchForm = Object.freeze({
  [EUserFieldNames.FirstName]: "",
  [EUserFieldNames.LastName]: "",
  [EUserFieldNames.Email]: "",
  [EUserFieldNames.Username]: "",
  [EUserFieldNames.EmployeeCode]: "",
});

export const signIn = async (formData: IFormData) => {
  const response = await callApi.post("/sign-in", formData);
  return response;
};

export const signOut = async () => {
  await callApi.post("/sign-out");
  window.location.reload();
  return {
    message: "Signed out.",
  }; // response;
};

export const refreshToken = async () => {
  const response = await callApi.post("/refresh");

  return response;
};

export const getUserProfileById = async (userId: number) => {
  const response = await callApi.get<{ id: number }>("/user/display/" + userId);

  return response;
};
export const createUser = async (formData: IFormData) => {
  const response = await callApi.post<{ id: number }>("/user/store", formData);

  return response;
};
export const addUserRole = async (formData: IFormData) => {
  const response = await callApi.post<{ id: number }>("/user/add-role", formData);

  return response;
};
export const deleteUserRole = async (formData: IFormData) => {
  const response = await callApi.post<{ id: number }>("/user/delete-role", formData);

  return response;
};
export const updateUser = async (formData: IFormData) => {
  const response = await callApi.put<undefined>(`/user/update/${formData.id}`, formData);

  return response;
};
export const deleteUser = async (formData: IFormData) => {
  const response = await callApi.delete<undefined>(`/user/delete/${formData.id}`);

  return response;
};
export const restoreUser = async (formData: IFormData) => {
  const response = await callApi.patch<undefined>(`/user/restore/${formData.id}`);

  return response;
};
export const removeUser = async (formData: IFormData) => {
  const response = await callApi.delete<undefined>(`/user/remove/${formData.id}`);

  return response;
};

export const updateUserStatus = async (formData: IFormData) => {
  const response = await callApi.patch<undefined>(`/user/change-status/${formData.id}`, formData);
  return response;
};
export const updateUserPassword = async (formData: IFormData) => {
  const response = await callApi.patch<undefined>(`/user/change-password/${formData.id}`, formData);
  return response;
};
export const getUsers = async (searchData?: IFormData, pagination: IPage = { size: 25, number: 1 }) => {
  const queryString = objToQueryString({ ...(searchData || {}), paginate: "1", perPage: pagination.size, page: pagination.number });
  const response = await callApi.get<IUser[]>(`/user/all?${queryString}`);

  return {
    data: response.responseData?.data || [],
    total: response.responseData?.total || 0,
  };
};
export const getUserRoles = async (searchData?: IFormData, pagination: IPage = { size: 25, number: 1 }) => {
  const queryString = objToQueryString({ ...(searchData || {}), paginate: "1", perPage: pagination.size, page: pagination.number });
  const response = await callApi.get<IUser[]>(`/user/roles?${queryString}`);

  return {
    data: response.responseData?.data || [],
    total: response.responseData?.total || 0,
  };
};
export const getUserActivities = async (searchData?: IFormData, pagination: IPage = { size: 25, number: 1 }) => {
  const queryString = objToQueryString({ ...(searchData || {}), paginate: "1", perPage: pagination.size, page: pagination.number });
  const response = await callApi.get<any[]>(`/user_log/user?${queryString}`);

  return {
    data: response.responseData?.data || [],
    total: response.responseData?.total || 0,
  };
};

export const getUserOptionList = async () => {
  const response = await callApi.get("/user/option-list");
  return response;
};

export const getUserVenderOptionList = async () => {
  const response = await callApi.get("/user/vender-development-list");
  return response;
};

export const getSalesOptionList = async () => {
  const response = await callApi.get("/user/sales-option-list");
  return response;
};
export const getSalesOptionNameList = async () => {
  const response = await callApi.get("/user/sales-all-option-name-list");
  return response;
};
export const getSalesAllOptionList = async () => {
  const response = await callApi.get("/user/sales-all-option-list");
  return response;
};

export const getUsersByRoleId = async (formData: IFormData) => {
  const response = await callApi.get(`/user/role/${formData.id}`);

  return {
    data: Array.isArray(response.responseData) ? response.responseData : [],
    total: response.responseData?.length || 0,
  };
};

export const getUsersByRoleIdWithDefault = async (formData: IFormData) => {
  const response = await callApi.get(`/user/role-default/${formData.id}`);

  return {
    data: Array.isArray(response.responseData) ? response.responseData : [],
    total: response.responseData?.length || 0,
  };
};

export const getUserFullName = async () => {
  const response = await callApi.get("/user/full-name");
  return response;
};

export const getUsersByRoleIdInSpecificProjectID = async (formData: IFormData) => {
  const response = await callApi.get<undefined>(`/user/project/${formData.project_id}/role/${formData.id}`);
  return {
    data: Array.isArray(response.responseData) ? response.responseData : [],
  };
};

export const updateColumnUser = async (formData: IFormData) => {
  const response = await callApi.post(`/user/update-column`, formData);
  return response;
};

//export const userApis: IApis<IUser> = Object.freeze({
export const userApis: IApis = Object.freeze({
  [EApiNames.Create]: {
    api: createUser,
  },
  [EApiNames.Update]: {
    api: updateUser,
  },
  [EApiNames.Delete]: {
    api: deleteUser,
  },
  [EApiNames.Restore]: {
    api: restoreUser,
  },
  [EApiNames.GetAll]: {
    api: getUsers,
  },
  [EApiNames.EnableDisable]: {
    api: updateUserStatus,
  },
  [EApiNames.Remove]: {
    api: removeUser,
  },
});
