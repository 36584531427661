import { Component, ReactNode } from "react";

export default class ErrorBoundary extends Component<{ fallback?: ReactNode }> {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const {
      props: { children, fallback },
      state: { hasError },
    } = this;

    return !hasError
      ? children
      : fallback || (
          <div className="p-3 text-center">
            <h3>Something went wrong! Please refresh or go to another page.</h3>
          </div>
        );
  }
}
