export enum ETagFieldNames {
  "ID" = "id",
  "Title" = "title",
  "Color" = "color",
  "BackgroundColor" = "backgroundColor",
  "ColumnName" = "columnName",
  "HighlightCategory" = "highlightCategoryId",
  "HighlightCategoryName" = "highlightCategoryName",
}
export interface ITag {
  [ETagFieldNames.ID]: number | null;
  [ETagFieldNames.Title]: string;
  [ETagFieldNames.Color]: string;
  [ETagFieldNames.BackgroundColor]: string;
  [ETagFieldNames.ColumnName]: string | null;
  [ETagFieldNames.HighlightCategory]?: number | null;
  [ETagFieldNames.HighlightCategoryName]?: string | null;
}
export enum EFormatFieldNames {
  "ID" = "id",
  "Text" = "text",
  "TagID" = "tagId",
  "StarLevel" = "starLevel",
  "ForCompare" = "forCompare",
  "HighlightCategory" = "highlightCategoryId",
}
export interface IFormat {
  [EFormatFieldNames.ID]: number | null;
  [EFormatFieldNames.Text]: string;
  [EFormatFieldNames.TagID]: number | null;
  [EFormatFieldNames.ForCompare]?: boolean;
  [EFormatFieldNames.HighlightCategory]?: number;
  [EFormatFieldNames.StarLevel]?: number;
}

export interface RFormat {
  [EFormatFieldNames.Text]: string;
}
