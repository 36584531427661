import { IAction } from "../../configs/types";
import { EUserFieldNames, IUserRole } from "../../services/users/userTypes";
import { EUserActionTypes } from "./userActions";

export interface IUserState {
  [EUserFieldNames.ID]: null | number;
  [EUserFieldNames.FirstName]: string;
  [EUserFieldNames.LastName]: string;
  [EUserFieldNames.Email]: string;
  [EUserFieldNames.Username]: string;
  [EUserFieldNames.EmployeeCode]: string;
  [EUserFieldNames.Roles]: IUserRole[];
  [EUserFieldNames.VendorID]: null | number;
  [EUserFieldNames.Theme]: number;
  [EUserFieldNames.PPT]:number;
}


const initialState: IUserState = Object.freeze({
  [EUserFieldNames.ID]: null,
  [EUserFieldNames.EmployeeCode]: "",
  [EUserFieldNames.FirstName]: "",
  [EUserFieldNames.LastName]: "",
  [EUserFieldNames.Username]: "",
  [EUserFieldNames.Roles]: [],
  [EUserFieldNames.Email]: "",
  [EUserFieldNames.VendorID]: null,
  [EUserFieldNames.Theme]: 0,
  [EUserFieldNames.PPT]: 0,
});

export default function userReducer(state = initialState, action: IAction<EUserActionTypes, IUserState>) {
  switch (action.type) {
    case EUserActionTypes.SignIn:
     
      return {
        ...state,
        ...(action.payload as IUserState),
      };
    case EUserActionTypes.Theme:
     
      return {
         ...state, theme: action.payload 
      };

      case EUserActionTypes.ppt:
     
      return {
         ...state, ppt: action.payload 
      };
    case EUserActionTypes.SignOut:
      localStorage.removeItem("token");
      localStorage.removeItem("tokenIssuedAt");
      localStorage.removeItem("tokenExpiresIn");
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
