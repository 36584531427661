import { EUserRoleName } from "../roles/roleTypes";

export enum EUserFieldNames {
  "ID" = "id",
  "FirstName" = "first_name",
  "LastName" = "last_name",
  "Email" = "email",
  "Username" = "name",
  "Password" = "password",
  "Roles" = "roles",
  "RoleID" = "role_id",
  "Phone" = "phone",
  "EmployeeCode" = "employee_code",
  "VendorID" = "vendor_id",
  "PPT" = "ppt",
  "Theme" = "theme"
}

export interface IUserRole {
  id: number;
  name: EUserRoleName;

  slug:
    | "superadmin"
    | "administrator"
    | "sales"
    | "sales-head"
    | "sales-order-management"
    | "production-manager"
    | "production-executive"
    | "project-support-crm"
    | "commercials"
    | "production-head"
    | "light-application-manager"
    | "product-design"
    | "partner"
    | "light-application-engineer"
    | "store"
    | "quality-executive"
    | "quality-head"
    | "user"
    | "operations-head"
    | "production-planning-&-control";
}

export interface IUser {
  [EUserFieldNames.ID]: number | null;
  [EUserFieldNames.FirstName]: string;
  [EUserFieldNames.LastName]: string;
  [EUserFieldNames.Email]: string;
  [EUserFieldNames.Username]: string;
  [EUserFieldNames.Roles]: IUserRole[];
  [EUserFieldNames.Phone]: number | null;
  [EUserFieldNames.EmployeeCode]: string | null;
  [EUserFieldNames.VendorID]: string | null;
  [EUserFieldNames.PPT]: 0 | 1;
  [EUserFieldNames.Theme]:  0 | 1;
}
