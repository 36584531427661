import { combineReducers } from "redux";

import userReducer, { IUserState } from "./user/userReducer";
import dataReducer, { IDataState } from "./data/dataReducer";
import screenReducer, { IScreenState } from "./user/FullScreenReducer";

export interface IRootState {
  user: IUserState;
  data: IDataState;
  screen: IScreenState;
}

const rootReducer = combineReducers({
  user: userReducer,
  data: dataReducer,
  screen: screenReducer,
});

export default rootReducer;
