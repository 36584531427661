import { IAction } from "../../configs/types";
import { IUserState } from "./userReducer";

export enum EUserActionTypes {
  "SignIn" = "SIGN_IN",
  "SignOut" = "SIGN_OUT",
  "Theme" = "THEME",
  "ppt" = "PPT"
};

export const userActionSignIn = (userData: IUserState): IAction<EUserActionTypes, IUserState> => ({
  type: EUserActionTypes.SignIn,
  payload: userData
});

export const userActionSignOut = (): IAction<EUserActionTypes> => ({
  type: EUserActionTypes.SignOut,
});

export const UpdateTheme = (themeValue: number): IAction<EUserActionTypes, number> => ({
 type: EUserActionTypes.Theme,
 payload: themeValue
});
export const UpdatePpt = (themeValue: number): IAction<EUserActionTypes, number> => ({
 type: EUserActionTypes.ppt,
 payload: themeValue
});

